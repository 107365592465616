<template>
  <div class="pay-success">
    <img
      class="ps"
      src="../../../assets/pay-success.svg"
    />
    <div class="pay-desc" style="font-weight: bold;">续费成功</div>
    <div class="pay-desc">
      您的会员有效时间已经延长
    </div>
    <div class="back-btn">
      <button @click="back">
        返回
      </button>
    </div>
    <div class="official-account">
      <div class="qr">
        <img src="../../../assets/official.png" />
        <div class="tip">微信扫码（长按保存识别）</div>
      </div>
      <div class="follow-detail">
        <div class="follow-prompt">关注微信公众号</div>
        <div class="info">每日更新游戏优惠信息</div>
        <div class="info">联系客服更方便</div>
        <div class="info">最新游戏资讯</div>
        <div class="info">switch 玩家交流群</div>
      </div>
    </div>
  </div>
</template>
<script>
import { reportError } from "@/utils/log"

export default {
  name: "PaySuccess",
  data() {
    return {
      orderId: "",
    }
  },
  components: {},
  methods: {
    back() {
      this.$router.push("/order?orderId=" + this.orderId)
    }
  },
  created() {
    this.$setNavShow(false)
    if (this.$route.query.orderId) {
      this.orderId = this.$route.query.orderId
    }
  },
}
</script>

<style lang="less" scoped>
.pay-success {
  width: 7.5rem;
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  align-items: center;
  .ps {
    margin-top: 0.38rem;
    width: 1.98rem;
    height: 1.98rem;
    &.traffic {
      width: 1.38rem;
      height: 1.38rem;
    }
  }
  .traffic-ps-desc {
    margin-top: 0.24rem;
    width: 7.02rem;
    border-radius: 0.1rem;
    box-shadow: 0px 0px 0.08rem 0.04rem rgba(0, 0, 0, 0.08);
    background: #ffffff;
    .key-title-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      .key-title {
        font-size: 0.32rem;
        color: #53cda9;
        margin: 0.24rem;
      }
    }
    .gas-info {
      font-size: 0.28rem;
      margin-left: 0.24rem;
      margin-bottom: 0.12rem;
      &:last-child {
        margin-bottom: 0.3rem;
      }
      .gas-available {
        color: #53CDA9;
        margin-top: 0.12rem;
        cursor: pointer;
        word-break:break-all;
      }
    }
  }
  .traffic-ps-remark {
    color: #9f9f9f;
    font-size: 0.28rem;
    margin-left: 0.24rem;
    margin-top: 0.24rem;
    margin-right: 0.24rem;
    .remark {
      margin-bottom: 0.06rem;
    }
  }
  .pay-desc {
    margin-top: 0.08rem;
    font-size: 0.36rem;
    color: #4c4c4c;
  }
  .back-btn {
    margin-top: 0.4rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      min-width: 2.2rem;
      border: none;
      border-radius: 0.4rem;
      font-size: 0.32rem;
      color: #53cda9;
      border: 0.02rem solid #53cda9;
      background: #ffffff;
      height: 0.8rem;
      ::after {
        border: none;
      }
    }
  }
  .divider {
    margin-top: 0.6rem;
    width: 100%;
    height: 0.02rem;
    background: #f6f7f8;
  }
  .official-account {
    margin-top: 0.36rem;
    font-size: 0.28rem;
    width: 6.54rem;
    display: flex;
    justify-content: space-between;
    .qr {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 2.94rem;
        height: 2.94rem;
      }
      .tip {
        font-size: 0.24rem;
        color: #717171;
      }
    }
    .follow-detail {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      .follow-prompt {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.28rem;
        height: 0.5rem;
        color: #ffffff;
        font-size: 0.26rem;
        background: #53cda9;
        border-radius: 0.06rem;
      }
    }
  }
}
</style>
